import _objectSpread from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
import KTUtil from "@/assets/js/components/util";
import validationMixin from "@/core/plugins/validation-mixin";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
export default {
  mixins: [validationMixin],
  name: "login",
  data: function data() {
    return {
      state: "signin",
      isDisabled: false,
      form: {
        email: process.env.NODE_ENV === "development" ? "admin@bthrust.com" : null,
        password: process.env.NODE_ENV === "development" ? "admin@123" : null
      }
    };
  },
  methods: {
    showForm: function showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(KTUtil.getById(form_name), "animate__animated animate__backInUp");
    },
    onSubmitLogin: function onSubmitLogin() {
      var _this = this;

      if (!_this.$refs.loginForm.validate()) {
        return false;
      }

      _this.formErrors = [];
      var user_email = _this.form.email;
      var password = _this.form.password; // clear existing errors

      _this.$store.dispatch(LOGOUT); // set spinner to submit button


      var submitButton = _this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      _this.isDisabled = true;

      _this.$store.dispatch(LOGIN, {
        user_email: user_email,
        password: password
      }).then(function () {
        var resolved = _this.$router.resolve({
          name: "admin.dashboard"
        });

        window.location.href = resolved.href;
      }).catch(function (error) {
        _this.logError(error);

        _this.isDisabled = false;
        submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
      });
    }
  },
  mounted: function mounted() {
    this.$store.dispatch(LOGOUT);
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    errors: function errors(state) {
      return state.auth.errors;
    }
  })), {}, {
    backgroundImage: function backgroundImage() {
      return process.env.VUE_APP_BASE_URL + "media/svg/illustrations/login-visual-1.svg";
    }
  })
};